import React, { Component } from "react";
import firebase from "../../firebase";
import numeral from "numeral";
import DataTable from "react-data-table-component";
import CompRowExpanded from "./CompRowExpanded";

export class CompetitorOrganic extends Component {
  constructor() {
    super();
    this.state = {
      keywords: [],
      toggledClearRows: false,
      filteredKeywords: [],
      keywordFilter: "",
      organic_traffic: "",
      organic_cost: "",
      columns: [
        {
          name: "Keyword",
          selector: "keyword",
          sortable: true,
          grow: 1,
          maxWidth: "250px"
        },
        {
          name: "Volume",
          selector: "volume",
          sortable: true,
          right: true,
          width: "80px"
        },
        {
          name: "Position",
          selector: "position",
          sortable: true,
          right: true,
          width: "80px"
        },
        {
          name: "Traffic",
          selector: "traffic",
          sortable: true,
          right: true,
          width: "60px"
        },
        {
          name: "Traffic %",
          selector: "traffic_percent",
          sortable: true,
          right: true,
          width: "80px"
        },
        {
          name: "Landing Page",
          selector: "url",
          sortable: true,
          maxWidth: "500px",
          cell: row => (
            <div>
              <a
                href={row.url}
                target="_blank"
                rel="noopener noreferrer"
                style={{ fontSize: "10px", textDecoration: "none" }}
              >
                {row.url}
              </a>
            </div>
          )
        },
        {
          name: "CPC",
          selector: "cpc",
          sortable: true,
          right: true,
          width: "80px"
        },
        {
          name: "Value",
          selector: "traffic_cost",
          sortable: true,
          right: true,
          width: "100px"
        },
        {
          name: "SEO",
          sortable: "true",
          selector: "seo_target",
          width: "80px",
          cell: function(row) {
            let icon = row.seo_target ? "fa fa-star text-warning" : "";
            return (
              <div>
                <i className={icon}></i>
              </div>
            );
          }
        },
        {
          name: "PPC",
          sortable: "true",
          selector: "ppc_target",
          width: "80px",
          cell: function(row) {
            let icon = row.ppc_target ? "fa fa-dollar-sign text-success" : "";
            return (
              <div>
                <i className={icon}></i>
              </div>
            );
          }
        }
      ]
    };

    this.handleKeywordFilter = this.handleKeywordFilter.bind(this);
    this.handleClearRows = this.handleClearRows.bind(this);
  }

  componentDidMount() {
    const compRef = firebase
      .database()
      .ref(`competitors/${this.props.competitor_id}`);

    compRef.on("value", snapshot => {
      let competitor = snapshot.val();
      let fbKeywords = competitor.keywords;
      let keywords = [];

      fbKeywords.forEach(function(keyword, index) {
        keywords.push({
          id: index,
          keyword: keyword.keyword,
          trends: keyword.trends,
          number_of_results: keyword.number_of_results,
          keyword_difficulty: keyword.keyword_difficulty,
          volume: parseInt(keyword.search_volume),
          position: parseInt(keyword.position),
          traffic: parseInt(keyword.traffic),
          traffic_percent: parseFloat(keyword.traffic_percent),
          url: keyword.url,
          cpc: parseFloat(keyword.cpc),
          traffic_cost: parseFloat(keyword.traffic_cost),
          serp_results: keyword.serp_results,
          seo_target: keyword.seo_target,
          ppc_target: keyword.ppc_target,
          competition: keyword.competition
        });
      });

      this.setState({
        keywords,
        filteredKeywords: keywords,
        keywordFilter: "",
        selectedRows: [],
        organic_traffic: competitor.organic_traffic,
        organic_cost: numeral(competitor.organic_cost).format("$0,0")
      });
    }); //end on value
  } //end componentDidMount

  handleKeywordFilterBackup(e) {
    let filterTerm = e.target.value;
    let filteredKeywords = this.state.keywords.filter(term => {
      return term.keyword.includes(filterTerm);
    });

    this.setState({
      keywordFilter: filterTerm,
      filteredKeywords
    });
  }

  handleKeywordFilter(e) {
    let filterString = e.target.value;
    let filters = filterString.split(",").map(term => term.trim());
    let filteredKeywords = [];

    //Update input field first to prevent keyboard lag
    this.setState({ keywordFilter: filterString });

    for (let i = 0; i < this.state.keywords.length; i++) {
      let keyword = this.state.keywords[i];
      let keywordValid = true;

      let posTerms = [];
      let negTerms = [];

      filters.forEach(term => {
        if (term.startsWith("-")) {
          negTerms.push(term.substr(1));
        } else {
          posTerms.push(term);
        }
      });

      if (posTerms.length) {
        posTerms.forEach(term => {
          if (keyword.keyword.includes(term)) {
            keywordValid = true;
          } else {
            keywordValid = false;
          }
        });
      }

      if (negTerms.length) {
        negTerms.forEach(term => {
          if (keyword.keyword.includes(term)) {
            keywordValid = false;
          }
        });
      }

      if (keywordValid) {
        filteredKeywords.push(keyword);
      }

      console.log(negTerms, posTerms);
    }

    this.setState({
      keywordFilter: filterString,
      filteredKeywords
    });
  }

  handleClearRows = () => {
    this.setState({ toggledClearRows: !this.state.toggledClearRows });
  };

  render() {
    return (
      <div
        className="container-fluid px-0"
        style={{ backgroundColor: "#fafafa" }}
      >
        <div className="row">
          <div className="col p-5">
            <h4 className="px-3 pt-0 pb-2">
              Estimated Monthly Organic Traffic :{" "}
              <strong>{this.state.organic_traffic}</strong>
            </h4>
            <h4 className="p-3 pb-0 pt-1">
              Organic Traffic Value : <strong>{this.state.organic_cost}</strong>
            </h4>
          </div>
        </div>

        <div className="row">
          <div className="col pt-0 pb-0 pr-5 text-right">
            <input
              className="p-2"
              type="text"
              placeholder="search keywords"
              onChange={this.handleKeywordFilter}
              value={this.state.keywordFilter}
            />
          </div>
        </div>

        <div className="row">
          <div className="col mx-auto p-5 page-text">
            <DataTable
              title="Organic Search Terms"
              columns={this.state.columns}
              data={this.state.filteredKeywords}
              defaultSortField="position"
              defaultSortAsc={true}
              clearSelectedRows={this.state.toggledClearRows}
              pagination={true}
              paginationRowsPerPageOptions={[20, 50, 100]}
              paginationPerPage={20}
              dense={true}
              expandableRows
              expandableRowsComponent={
                <CompRowExpanded compId={this.props.competitor_id} />
              }
            />
          </div>
        </div>
      </div>
    );
  }
}

export default CompetitorOrganic;
