import React, { Component } from "react";
import firebase from "../../firebase";
import axios from "axios";
import Trends from "./cells/Trends";

export class TableRowExpanded extends Component {
  constructor(props) {
    super();

    this.state = {
      serp_results: false,
      isLoaded: false,
      related: []
    };
  }

  componentDidMount() {
    if (this.props.data.serp_results === false) {
      const searchTerm = encodeURI(this.props.data.keyword);
      const getSERP = axios.get(
        `https://app.zenserp.com/api/v2/search?q=${searchTerm}&hl=en&gl=US&location=United%20States&search_engine=google.com&tbm=&num=10&start=0&apikey=910b4680-df30-11e9-9037-8d16b322f9fa`
      );

      Promise.all([getSERP])
        .then(res => {
          let related =
            typeof res[0].data.related_searches === undefined
              ? []
              : res[0].data.related_searches;
          this.setState({
            serp_results: res[0].data,
            isLoaded: true,
            related
          });
          const keywordsRef = firebase
            .database()
            .ref(`keywords/${this.props.data.id}`);
          keywordsRef.update({ serp_results: res[0].data });
        })
        .catch(err => console.log(err));
    } else {
      let related = [];

      if ("related_searches" in this.props.data.serp_results) {
        related = this.props.data.serp_results.related_searches;
      }
      this.setState({
        serp_results: this.props.data.serp_results,
        isLoaded: true,
        related
      });
    }
  }

  makeSeoTarget = () => {
    const keywordsRef = firebase
      .database()
      .ref(`keywords/${this.props.data.id}`);
    keywordsRef.update({ seo_target: !!!this.props.data.seo_target });
  };

  makePpcTarget = () => {
    const keywordsRef = firebase
      .database()
      .ref(`keywords/${this.props.data.id}`);
    keywordsRef.update({ ppc_target: !!!this.props.data.ppc_target });
  };

  makeNegTerm = () => {
    const keywordsRef = firebase
      .database()
      .ref(`keywords/${this.props.data.id}`);
    keywordsRef.update({ negative_term: !!!this.props.data.negative_term });
  };

  render() {
    return (
      <div
        className="container-fluid"
        style={{ maxWidth: "100vw", backgroundColor: "#fafafa" }}
      >
        <div className="row">
          <div className="col pt-4 pl-4">
            Actions:
            <div className="form-check form-check-inline pl-2">
              <input
                className="form-check-input"
                type="checkbox"
                value=""
                checked={this.props.data.seo_target && "checked"}
                onChange={this.makeSeoTarget}
              />
              <label className="form-check-label">SEO Target</label>
            </div>
            <div className="form-check form-check-inline pl-2">
              <input
                className="form-check-input"
                type="checkbox"
                value=""
                checked={this.props.data.ppc_target && "checked"}
                onChange={this.makePpcTarget}
              />
              <label className="form-check-label">PPC Target</label>
            </div>
            <div className="form-check form-check-inline pl-2">
              <input
                className="form-check-input"
                type="checkbox"
                value=""
                checked={this.props.data.negative_term && "checked"}
                onChange={this.makeNegTerm}
              />
              <label className="form-check-label">Negative Term</label>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-6 pt-4">
            {this.state.serp_results &&
              this.state.serp_results.organic.map(term => (
                <div key={term.position} className="p-2">
                  {term.title && (
                    <div>
                      <strong>{term.title}</strong>
                      <p>{term.description}</p>
                      <small>
                        <a href={term.url}>{term.url}</a>
                      </small>
                    </div>
                  )}
                  {term.questions && (
                    <div>
                      <h6>People Also Ask:</h6>
                      <ul className="p-4">
                        {term.questions.map(question => (
                          <li key={question.question}>{question.question}</li>
                        ))}
                      </ul>
                    </div>
                  )}
                  <hr />
                </div>
              ))}
          </div>

          <div className="col-6 p-4">
            <Trends trend={this.props.data.trend} />
            <div className="pt-4">
              <p>Related Searches</p>
              <ul>
                {this.state.related.map(term => (
                  <li key={term.title}>{term.title}</li>
                ))}
              </ul>
            </div>
          </div>
        </div>
        <div className="row" style={{ display: "none" }}>
          <div className="col">
            <h5>Query Data</h5>
            <pre>{JSON.stringify(this.props.data, null, 2)}</pre>
          </div>
          <div className="col">
            <h5>SERP Results</h5>
            <pre>{JSON.stringify(this.state.serp_results, null, 2)}</pre>
          </div>
        </div>
      </div>
    );
  }
}

export default TableRowExpanded;
