// src/firebase.js
import firebase from "firebase";

const config = {
  apiKey: "AIzaSyB8cCkeX0KF_PgEfVgM2U3PqE2VtDKWUEI",
  authDomain: "federalmerchseo.firebaseapp.com",
  databaseURL: "https://federalmerchseo.firebaseio.com",
  projectId: "federalmerchseo",
  storageBucket: "federalmerchseo.appspot.com",
  messagingSenderId: "229313624613",
  appId: "1:229313624613:web:d2fe8b837b8edbc31b9dcc"
};
//

firebase.initializeApp(config);

export default firebase;
